var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"login-page"},[_c('div',{staticClass:"login-box"},[_c('div',{staticClass:"login-center"},[_c('h1',{staticClass:"sys-title"},[_vm._v("欢迎登录系统")]),_c('div',{staticStyle:{"height":"0.8%","width":"8%","background-color":"#556BFFFF","margin-left":"46%","margin-bottom":"40px"}}),_c('ax-form',{ref:"form",attrs:{"formBuilder":_vm.formBuilder},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.login.apply(null, arguments)}},scopedSlots:_vm._u([{key:"username",fn:function({ record }){return _c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            record.model,
            {
              rules: record.rules,
            },
          ]),expression:"[\n            record.model,\n            {\n              rules: record.rules,\n            },\n          ]"}],attrs:{"size":"large","placeholder":"用户名/手机号码"}},[_c('img',{staticClass:"icon-image",attrs:{"slot":"prefix","src":require("../../assets/images/user.png"),"alt":""},slot:"prefix"})])}},{key:"password",fn:function({ record }){return _c('a-input-password',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            record.model,
            {
              rules: record.rules,
            },
          ]),expression:"[\n            record.model,\n            {\n              rules: record.rules,\n            },\n          ]"}],attrs:{"size":"large","placeholder":"密码"}},[_c('img',{staticClass:"icon-image",attrs:{"slot":"prefix","src":require("../../assets/images/lock.png"),"alt":""},slot:"prefix"})])}},{key:"captcha",fn:function({ record }){return [_c('div',{staticStyle:{"display":"flex"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                record.model,
                {
                  rules: record.rules,
                },
              ]),expression:"[\n                record.model,\n                {\n                  rules: record.rules,\n                },\n              ]"}],attrs:{"size":"large","placeholder":"请输入验证码"}}),_c('img',{staticStyle:{"height":"30px","width":"100px","margin-left":"10px","cursor":"pointer"},attrs:{"src":_vm.authImage,"alt":""},on:{"click":_vm.getAuthCode}})],1)]}}])},[_c('ax-button',{staticClass:"ax-button",staticStyle:{"margin-top":"20px"},attrs:{"slot":"loginBtn","size":"large","block":"","text":"登录","loading":_vm.loading},on:{"click":_vm.login},slot:"loginBtn"}),_c('div',{staticClass:"register",attrs:{"slot":"switchBtn"},slot:"switchBtn"})],1),_vm._m(0)],1)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bottom"},[_c('div',{staticClass:"item"}),_c('div',{staticStyle:{"letter-spacing":"8px","margin-left":"8px"}},[_vm._v("启云企业服务一站式服务平台")]),_c('div',{staticClass:"item"})])
}]

export { render, staticRenderFns }