<template>
  <div class="login-page">
    <div class="login-box">
      <div class="login-center">
        <h1 class="sys-title">欢迎登录系统</h1>
        <div style="height: 0.8%;width: 8%;background-color: #556BFFFF;margin-left: 46%;margin-bottom: 40px;"></div>
        <ax-form
          ref="form"
          :formBuilder="formBuilder"
          @keyup.enter.native="login"
        >
          <a-input
            size="large"
            v-decorator="[
              record.model,
              {
                rules: record.rules,
              },
            ]"
            slot="username"
            slot-scope="{ record }"
            placeholder="用户名/手机号码"
          >
            <!-- <a-icon slot="prefix" type="user" style="color: #fff;" /> -->
            <img
              class="icon-image"
              src="../../assets/images/user.png"
              slot="prefix"
              alt=""
            />
          </a-input>

          <a-input-password
            size="large"
            v-decorator="[
              record.model,
              {
                rules: record.rules,
              },
            ]"
            slot="password"
            slot-scope="{ record }"
            placeholder="密码"
          >
            <!-- <a-icon slot="prefix" type="lock" style="color: #fff;" /> -->
            <img
              class="icon-image"
              src="../../assets/images/lock.png"
              slot="prefix"
              alt=""
            />
          </a-input-password>

          <template slot="captcha" slot-scope="{ record }">
            <div style="display: flex">
              <a-input
                size="large"
                v-decorator="[
                  record.model,
                  {
                    rules: record.rules,
                  },
                ]"
                placeholder="请输入验证码"
              >
              </a-input>
              <img
                :src="authImage"
                alt=""
                style="
                  height: 30px;
                  width: 100px;
                  margin-left: 10px;
                  cursor: pointer;
                "
                @click="getAuthCode"
              />
            </div>
          </template>

          <ax-button
            size="large"
            slot="loginBtn"
            block
            text="登录"
            :loading="loading"
            style="margin-top: 20px"
            class="ax-button"
            @click="login"
          />
          <div class="register" slot="switchBtn">
            <!-- <div><a-switch v-model="checked" />
              <a>记住密码</a>
            </div>
            <a>立即注册</a> -->
          </div>
        </ax-form>
        <div class="bottom">
          <div class="item"></div>
          <div style="letter-spacing:8px;margin-left: 8px;">启云企业服务一站式服务平台</div>
          <div class="item"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { post, get } from "@/common/request";
import cookieUtil from "@/common/cookie-util";
import { mapMutations,mapGetters } from "vuex";
export default {
  components: {},
  name: "Login",
  data() {
    return {
      loading: false,
      authImage: "",
      checkKey: "",
      formBuilder: this.$common.initGridFormData(
        [
          {
            type: "input",
            model: "username",
            rules: [{ required: true, message: "请输入账号" }],
          },
          {
            type: "input",
            model: "password",
            rules: [{ required: true, message: "请输入密码" }],
          },
          {
            type: "input",
            model: "captcha",
            rules: [{ required: true, message: "请输入验证码" }],
          },
          {
            type: "input",
            model: "loginBtn",
          },
          {
            type: "input",
            model: "switchBtn",
          },
        ],
        {
          labelWidth: 40,
          hideRequiredMark: true,
          colon: false,
          col: { span: 24 },
        }
      ),
      checked: true,
    };
  },
  created() {
    this.setTagList([]);
    this.getAuthCode();
  },
  computed: {
    ...mapGetters({
      menuList: "app/menuList",
    }),
  },
  methods: {
    ...mapMutations("app", ["setTagList"]),
    login() {
      this.$refs.form.form.validateFields((errors, values) => {
        if (!errors) {
          this.loading = true;
          values.requestType = "PC";
          post("/auth/login", {
            ...values,
            tenantId: 3,
            captchaFlag: true,
            checkKey: this.checkKey,
          })
            .then((res) => {
              const { tokenInfo, userInfo, loginStatus } = res?.data;
              cookieUtil.setToken(tokenInfo.tokenValue);
              this.$store.commit("app/setLoginStatus", loginStatus);
              this.$store.commit("app/setUserInfo", userInfo);
              this.loading = false;
              console.log(res, "res");
              
              // 跳转
              this.$router.push("/");
            })
            .catch((e) => {
              console.error(e);
              this.loading = false;
              this.getAuthCode();
            });
        }
      });
    },
    // 下载文件
    downFileList() {
      window.open(
        `${process.env.VUE_APP_BASE_URL}/file/download/?id=1554041369053184002`
      );
    },
    // 获取验证码
    getAuthCode() {
      this.checkKey = new Date().getTime();
      get(`/auth/randomImage/${this.checkKey}`)
        .then((res) => {
          // console.log(res, "res"); 
          this.authImage = res.data;
        })
        .catch(() => {});
    },
  },
};
</script>

<style lang="less" scoped>
.login-page {
  height: 100%;
  width: 100%;
  position: relative;
  background: url("../../assets/login/login.png") ;
  background-size: cover;
  background-position: 0px 0px;

  .login-box {
    position: fixed;
    top: 27%;
    left: 65%;
    margin: auto;
    width: 540px;
    height: 530px;
    .login-image {
      height: 180px;
      width: 100%;
      margin-bottom: 5px;
      img {
        width: 100%;
        height: 100%;
      }
    }

    .login-center {
      height: 100%;
      width: 100%;
      border-radius: 15px;
      background-color: white;
      background-size: 100% 100%;
      opacity: 1;
      padding: 40px 50px 40px 50px;

      .sys-title {
        text-align: center;
        // color: #fff;
        // margin-bottom: 40px;
      }

    
      .bottom {
        height: 10%;
        color: #afb9c5;
        letter-spacing: 8px;
        text-align: left;
        font-style: normal;
        text-transform: none;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        .item {
          height: 1%;
          width: 18%;
          background-color:  #AFB9C5;;
        }
      }
    }
  }
  .logo-box {
    width: 230px;
    height: 65px;
    position: fixed;
    top: 4%;
    left: 3%;
  }

  .icon-image {
    height: 18px; 
    width: 18px;
    position: absolute;
    left: -4px;
  }
}

/deep/.ant-input {
  border: none;
  background: #f2f4f9 !important;
  color: #999999 !important;
  height: 50px;
}

/deep/.ant-btn-primary {
  background-color: #2c74ff !important;
  border: none;
  height: 50px;
  font-weight: 800;
  font-size: 20px;
  border-radius: 10px;
}
</style>
